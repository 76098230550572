import { useQuery } from '@tanstack/react-query'
import getConfig from 'next/config'
import { convertCaseList, ConvertType } from '@/shared/utils/convertString'

import axiosInstance from '@/shared/utils/axios'

import { IProfile, IProfileResponse } from '../types'
import { getCookie } from '@/shared/utils/cookies'

export const fetchProfile = async () => {
  const resp = await axiosInstance.get<IProfileResponse>(
    `${process.env.NEXT_PUBLIC_API_ENDPOINT}/me`,
  )
  return convertCaseList(resp.data, ConvertType.CAMEL) as IProfile
}

export const useProfileQuery = () => {
  const accessToken = getCookie('access_token')
  return useQuery({
    queryKey: ['profile'],
    queryFn: () => fetchProfile(),
    enabled: !!accessToken,
  })
}
