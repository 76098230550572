'use client'

import { LoadingOverlay } from '@mantine/core'
import Lottie from 'react-lottie'

import animationData from '@/public/lottie/loading.json'

interface ILoadingProps {
  loadingText?: string
}

const Loading = ({ loadingText }: ILoadingProps) => {
  return (
    <LoadingOverlay
      overlayProps={{ opacity: 0.55, color: '#ffffff' }}
      loaderProps={{
        children: (
          <div className="h-[200px] w-[200px]">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData,
              }}
            />
            {loadingText && <p className="mt-2 text-lg font-medium">{loadingText}</p>}
          </div>
        ),
      }}
      visible={true}
      zIndex={1000}
    />
  )
}

export default Loading
